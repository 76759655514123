'use strict';

const handleReviews = () => {

    const reviews: NodeListOf<HTMLElement> = document.querySelectorAll('.js-review');

	if (reviews.length === 0) {
		return;
	}

	const mql: MediaQueryList = window.matchMedia('(min-width: 1200px)');

	reviews.forEach((item: HTMLElement) => {
		const toggler: HTMLElement|null = item.querySelector('.js-review-toggler');
		if (!toggler) {
			return;
		}
		toggler.addEventListener('click', () => {
			item.classList.add('active');
			reviews.forEach((review: HTMLElement) => {
				if (review !== item) {
					review.classList.remove('active');
				}
			});
		});
	});

	mql.addEventListener('change', () => {
		if (mql.matches) {
			reviews.forEach((item: HTMLElement, key: number) => {
				if (key === 0) {
					item.classList.add('active');
				} else {
					item.classList.remove('active');
				}
			});
		}
	});

	document.addEventListener('click', (event: MouseEvent) => {
		console.log(event)

		if (mql.matches) {
			const productsActives: NodeListOf<HTMLElement> = document.querySelectorAll('.fpProducts__item.js-collapse.active');
			console.log(productsActives)

			if(productsActives.length > 0){
				console.log('hay productos activos')
				reviews.forEach((item: HTMLElement, key: number) => {
					if (key === 2) {
						item.classList.add('active');
					} else {
						item.classList.remove('active');
					}
				});
			}
		}
	});



}

export default handleReviews;