// Import the necessary modules...

import accordion from "./src/accordion.js";
import handleAnimation from "./src/handleAnimation.js";
import handleCollapse from "./src/handleCollapse.js";
import handleMobileSlider from "./src/handleMobileSlider.js";
import handleNav from "./src/handleNav.js";
import handleReviews from "./src/handleReviews.js";

window.addEventListener('DOMContentLoaded', function() {

    //wait until images, links, fonts, stylesheets, and js is loaded
    window.addEventListener("load", function() {

        handleNav();

        if (document.querySelector('.js-lottie')) {
            handleAnimation();
        }

        if (document.querySelector('.js-mobile-slider')) {
            handleMobileSlider();
        }

        if (document.querySelector('.js-collapse')) {
            handleCollapse();
        }

        if (document.querySelector('.js-review')) {
            handleReviews();
        }

        if (document.querySelector('.accordion')) {
            accordion();
        }
   });

});