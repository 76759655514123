const handleNav = () => {

	const headerNav: HTMLElement|null = document.querySelector('.headerNav__wrapper');
	const navToggler: HTMLElement|null = document.querySelector('.js-nav-toggler');

	if (!headerNav || !navToggler) {
		return;
	}

	navToggler.addEventListener('click', () => {
		if(headerNav.classList.contains('active')) {
			document.body.classList.remove('nav-active');
			navToggler.classList.remove('active');
			headerNav.classList.remove('active');
			setTimeout(() => {
				headerNav.style.display = 'none';
			}, 300);
		} else {
			document.body.classList.add('nav-active');
			navToggler.classList.add('active');
			headerNav.style.display = 'grid';
			setTimeout(() => {
				headerNav.classList.add('active');
			}, 50);
		}
	});
	
	//Mobile nav sub-menu toggler
	const expandSubMenu = (toggler: HTMLElement, subMenu: HTMLElement, expanded: boolean) => {
		expanded = !expanded;

		subMenu.style.maxHeight = expanded ? `${subMenu.scrollHeight}px` : '0';
		toggler.classList.toggle('active', expanded);

		return expanded;
	}

	const subMenuTogglers: NodeListOf<HTMLElement> = document.querySelectorAll('.menu-item-has-children');

	subMenuTogglers.forEach((toggler: HTMLElement) => {
		let expanded: boolean = false;
		const subMenu: HTMLElement|null = toggler.querySelector('.sub-menu');
		if (subMenu) {
			toggler.addEventListener('click', () => {
				expanded = expandSubMenu(toggler, subMenu, expanded);
			});
			if (toggler.classList.contains('current-menu-ancestor')) {
				expanded = expandSubMenu(toggler, subMenu, expanded);
			}
			subMenu.addEventListener('click', (e) => e.stopPropagation());
		}
	});

	//Reset mobile nav on resize
	const mql: MediaQueryList = window.matchMedia('(min-width: 992px)');
	window.addEventListener('resize', () => {
		if (mql.matches) {
			document.body.classList.remove('nav-active');
			navToggler.classList.remove('active');
			headerNav.classList.remove('active');
			headerNav.style.display = 'grid';
		}
	});

	// Fix behavior on page load
	if(!mql.matches) {
		headerNav.classList.remove('active');
	}

}

export default handleNav;