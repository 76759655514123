const handleCollapse = () => {

	const collapses: NodeListOf<HTMLElement> = document.querySelectorAll('.js-collapse');

	if (collapses.length === 0) {
		return;
	}

	collapses.forEach((item: HTMLElement) => {
		let expanded: boolean = false;
		const toggler: HTMLElement|null = item.querySelector('.js-collapse-toggle');
		const content: HTMLElement|null = item.querySelector('.js-collapse-content');
		if (!toggler || !content) {
			return;
		}
		toggler.addEventListener('click', () => {
			content.style.maxHeight = item.classList.toggle('active') ? `${content.scrollHeight}px` : '0';
			collapses.forEach((collapse: HTMLElement) => {
				if (collapse !== item) {
					const otherContent: HTMLElement|null = collapse.querySelector('.js-collapse-content');
					if (otherContent) {
						otherContent.style.maxHeight = '0';
						collapse.classList.remove('active');
					}
				}
			});
		});
	});

	document.addEventListener('click', (event: MouseEvent) => {
		const target: HTMLElement|null = event.target as HTMLElement;
		if (target && !target.closest('.js-collapse.active')) {
			collapses.forEach((collapse: HTMLElement) => {
				const content: HTMLElement|null = collapse.querySelector('.js-collapse-content');
				if (content) {
					content.style.maxHeight = '0';
					collapse.classList.remove('active');
				}
			});
		}
	});

}

export default handleCollapse;