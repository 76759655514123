'use strict';

import lottie from "lottie-web";

const handleAnimation = () => {

	const items: NodeListOf<HTMLElement> = document.querySelectorAll('.js-lottie');
	if (!items.length) {
		return;
	}

	items.forEach((item: HTMLElement) => {
		if (!item.dataset.lottieJson) {
			return;
		}
		const anim = lottie.loadAnimation({
			container: item, // the dom element that will contain the animation
			renderer: 'svg',
			loop: true,
			autoplay: (item.dataset.lottieAutoplay && item.dataset.lottieAutoplay === 'true') ? true : false,
			path: item.dataset.lottieJson // the path to the animation json
		});

		if (item.dataset.lottieHover) {

			const trigger: HTMLElement|null = document.getElementById(item.dataset.lottieHover);

			if (!trigger) {
				return;
			}
			trigger.addEventListener('mouseenter', () => {
				anim.play();
			});

			trigger.addEventListener('mouseleave', () => {
				anim.stop();
			});

		}
	});

}

export default handleAnimation;