'use strict';

import Swiper from "swiper";
import { FreeMode } from "swiper/modules";
import { SwiperOptions } from 'swiper/types';

const handleMobileSlider = () => {

	const swiperContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.js-mobile-slider');

	if (swiperContainers.length === 0) {
		return;
	}

	const mql: MediaQueryList = window.matchMedia('(max-width: 1199px)');

	const swiperInstances: Swiper[] = [];

	const swiperOptions: SwiperOptions = {
		modules: [FreeMode],
		spaceBetween: 21,
		slidesPerView: 'auto',
		freeMode: true,
		loop: false,
		grabCursor: true,
	}

	const manageSwiperClass = (container: HTMLElement, action: string) => {
		const swiperElements: NodeListOf<HTMLElement> = container.querySelectorAll('[data-swiper-class]');
		if (swiperElements.length !== 0) {
			swiperElements.forEach((element: HTMLElement) => {
				switch (action) {
					case 'remove':
						element.classList.remove(element.dataset.swiperClass || '');
						break;
					case 'add':
						element.classList.add(element.dataset.swiperClass || '');
						break;
				}
			});
		}
	}

	const removeSpaceBetween = (container: HTMLElement) => {
		const slides: NodeListOf<HTMLElement> = container.querySelectorAll('.swiper-slide');
		if(slides.length === 0) {
			return;
		}
		slides.forEach((slide: HTMLElement) => {
			slide.style.margin = '';
		});
	}

	const createSwiper = (container: HTMLElement, options: SwiperOptions) => {
		
		if (container.dataset.swiperSpaceBetween) {
			options.spaceBetween = parseInt(container.dataset.swiperSpaceBetween);
		}
		swiperInstances.push(new Swiper(container, options));
	}

	const destroySwiper = (index: number) => {
		if (swiperInstances[index]) {
			swiperInstances[index].destroy(true, true);
			delete swiperInstances[index];
		}
	}

	const handle = () => {
		swiperContainers.forEach((container, index) => {
			if (mql.matches && !swiperInstances[index]) {
				manageSwiperClass(container, 'add');
				createSwiper(container, swiperOptions);
			} else {
				destroySwiper(index);
				removeSpaceBetween(container);
				manageSwiperClass(container, 'remove');
			}
		});
	}

	mql.addEventListener('change', handle);
	handle();

}

export default handleMobileSlider;