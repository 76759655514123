'use strict';

const accordion = () => {

	const accordeons: NodeListOf<HTMLElement> = document.querySelectorAll('.accordion');

    if (accordeons.length === 0) {
        return;
    }

    accordeons.forEach((item: HTMLElement) => {
        const toggler: HTMLElement|null = item.querySelector('.accordion__header');
        const content: HTMLElement|null = item.querySelector('.accordion__content__cropped');
        if (!toggler || !content) {
            return;
        }
        toggler.addEventListener('click', () => {
            toggler.classList.toggle('active');
            content.style.maxHeight = item.classList.toggle('active') ? `${content.scrollHeight}px` : '0';
        });
    });
}

export default accordion;